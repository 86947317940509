import axiosConfig from "../../axiosConfig";
import { AxiosResponse } from "axios";

export interface ILogin {
    login(username: string, password: string): Promise<AxiosResponse<UserTokens>>;
    register(username: string, email: string, password: string, confirmPassword: string): Promise<AxiosResponse<UserTokens>>;
    getUser(username: string): Promise<AxiosResponse<UserModel>>;
    checkUserTaken(username: string): Promise<AxiosResponse<boolean>>;
    sendForgotPasswordEmail(username: string): Promise<AxiosResponse<ForgotPassword>>;
    resetPassword(password: string, confirmPassword: string, token: string): Promise<AxiosResponse<ForgotPasswordToken>>;
}

export class Login implements ILogin {

    login(username: string, password: string) {
        return axiosConfig.post("auth/login", { username, password });
    }

    register(username: string, email: string, password: string, confirmPassword: string) {
        return axiosConfig.post("auth/register-mobile", { username, email, password, confirmPassword });
    }

    getUser(username: string) {
        return axiosConfig.get("user/get/" + username);
    }

    checkUserTaken(username: string) {
        return axiosConfig.get("auth/user-taken/" + username);
    }

    sendForgotPasswordEmail(username: string): Promise<AxiosResponse<ForgotPassword>> {
        return axiosConfig.post("auth/send-forgot-password-email", { username });
    }

    resetPassword(password: string, confirmPassword: string, token: string): Promise<AxiosResponse<ForgotPasswordToken>> {
        return axiosConfig.post("auth/reset-password", { password, confirmPassword, token });
    }
}

export interface UserTokens {
    access_token: string;
    refresh_token: string;
}

export interface UserModel {
    id: number;
    username: string;
    email: string;
}

export interface ForgotPassword {
    success: boolean;
    email: string | undefined;
}

export interface ForgotPasswordToken {
    success: boolean;
    username: string;
    access_token: boolean;
    refresh_token: boolean;
}
